.sidebar-main-container{
    background-color: #8039DF;
    height: calc(100vh - 20px);
    color: #ffffff;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
}

.logo{
    width: 25px;
    height: 25px;
}

.sidebar-icons{
    cursor: pointer;
}

.sidebar-icon-container{
    margin-bottom: 20px;
}

.selected-side-bar-icon{
    width: 40px;
    padding-top: 3px;
    margin-bottom: 20px;
    background: #F5EDFF;
    color: #8039DF;
    border-radius: 50%;
}